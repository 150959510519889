<template>
    <v-container>
        <v-btn
            text
            color="primary-color"
            class="mb-n1"
            :title="backButtonLabel"
            to="/profile/credits"
        >
            <v-icon small left>arrow-left</v-icon>
            {{ backButtonLabel }}
        </v-btn>
        <v-row justify="center" :dense="$vuetify.breakpoint.smAndDown">
            <v-col md="auto">
                <router-view
                    @appointment="setAppointmentLink"
                    @no-appointment="hideAppointmentCard"
                />
            </v-col>
        </v-row>
        <v-row
            justify="center"
            class="pt-md-6"
            :dense="$vuetify.breakpoint.smAndDown"
        >
            <v-col
                v-if="hasAppointmentLink"
                :md="adsColumnWidth"
                class="d-flex justify-center"
            >
                <product-action :link="appointmentLink" style="max-width: 100%">
                    <template #title>Bulk Pricing</template>
                    <template #description>
                        Do you need higher volume, or would like to explore more
                        ways we can grow your business and organic traffic?
                        <br />
                        Please book a call to see what options are the best fit.
                    </template>
                    <template #button>Book Appointment</template>
                </product-action>
            </v-col>
            <v-col :md="adsColumnWidth" class="d-flex justify-center">
                <product-action :link="servicesLink" style="max-width: 100%">
                    <template #title>
                        100% Done For You Organic Traffic
                    </template>
                    <template #description>
                        Are you an established business doing over $1M per year?
                        We can grow your organic traffic for you...
                    </template>
                    <template #button>Book Appointment</template>
                </product-action>
            </v-col>
            <v-col :md="adsColumnWidth" class="d-flex justify-center">
                <product-action :link="partnersLink" style="max-width: 100%">
                    <template #title>Agency & Reseller Partnership</template>
                    <template #description>
                        Are you interested in Partnering with AmpiFire to
                        whitelabel or resell our services? Get our best bulk
                        rates and our exclusive sales tools.
                    </template>
                    <template #button>Apply as partner</template>
                </product-action>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ProductAction } from '@/components/ProductCard';

import type { NavigationGuardNext, Route } from 'vue-router';

Component.registerHooks(['beforeRouteEnter']);

@Component({
    components: {
        ProductAction
    }
})
export default class Purchase extends Vue {
    hasAppointmentLink = true;

    appointmentLink = '';

    cameFromCredits = false;

    get backButtonLabel() {
        return this.cameFromCredits ? 'Back to Credits' : 'All Your Credits';
    }

    get partnersLink() {
        return this.toLink('https://now.ampifire.com/partners', {
            utm_source: 'in-app',
            utm_medium: 'credits-page',
            utm_campaign: 'partner-upgrade',
            utm_term: this.$route.params.product
        });
    }

    get servicesLink() {
        return this.toLink('https://now.ampifire.com/start', {
            utm_source: 'in-app',
            utm_medium: 'credits-page',
            utm_campaign: 'managed-upgrade',
            utm_term: this.$route.params.product
        });
    }

    get adsColumnWidth() {
        return this.hasAppointmentLink ? 4 : 6;
    }

    beforeRouteEnter(
        _to: Route,
        from: Route,
        next: NavigationGuardNext<Purchase>
    ) {
        next((vm: Purchase) => {
            vm.cameFromCredits = from.path === '/profile/credits';
        });
    }

    setAppointmentLink(link: string) {
        this.appointmentLink = link;
    }

    hideAppointmentCard() {
        this.hasAppointmentLink = false;
    }

    toLink(base: string, params: Record<string, string> = {}) {
        const url = new URL(base);

        return new URL(
            `${url.origin}${url.pathname}?${new URLSearchParams([
                ...Array.from(url.searchParams.entries()),
                ...Object.entries(params)
            ])}`
        ).href;
    }
}
</script>
